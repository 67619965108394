<template>
  <div id="userLayout" class="userLayout" :class="['user-layout-wrapper']">
    <div class="logo">
      <img src="../assets/logo.jpg" :alt="settings.logo_title || 'LOGO'" />
    </div>
    <h1 class="title">{{ settings.web_platform_name }}</h1>
    <div class="content">
      <Login />
    </div>
    <footer>
      <span>地址：{{ settings.footer_address }}</span>
      <span>客服电话：{{ settings.footer_customer_service }}</span>
      <span>Copyright &copy; <a :href="settings.footer_company_name_link" target="_blank">{{ settings.footer_company_name }}</a> 版权所有</span>
      <span><a href="https://beian.miit.gov.cn/" target="_blank">{{ settings.footer_web_record_number }}</a></span>
      <span style="margin: 0;">增值电信业务经营许可证：{{settings.footer_ICP_number}}</span>
    </footer>
  </div>
</template>

<script>
  import Login from './login'
  export default {
    components: { Login },
    data() {
      return {
        settings: {
          contract_template: "0",
          footer_ICP_number: "津B2-20200232",
          footer_address: "天津经济技术开发区第一大街79号泰达MSD-C区C2座21层2105室",
          footer_company_name: "天津快桔安运货运有限公司",
          footer_company_name_link: "",
          footer_customer_service: "400-670-7868",
          footer_jiancheng_name: "司机和货主",
          footer_web_record_number: "津ICP备20004605号-1",
          logo_title: "快桔信息",
          vehicle_track_switch: "1",
          web_introduce: "",
          web_platform_name: "快桔信息"
        }
      }
    }
  }
</script>

<style lang="less" scoped>
@font-face{
  font-family: 'title-font';
  src: url('../assets/DMFT1604624505499.ttf') format('truetype');
}
#userLayout.user-layout-wrapper {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: url('../assets/bg.png') no-repeat;
  background-size: 100% 100%;
  .content {
    position: fixed;
    right: 6.66vw;
    top: calc(34.72vh - 60px);
    width: 400px;
    height: 100px;
  }
  .logo {
    width: 100px;
    height: 80px;
    position: fixed;
    left: 22px;
    top: 20px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .title {
    font-family: 'title-font';
    font-size: 40px;
    color: #fff;
    position: fixed;
    left: 125px;
    top: 30px;
  }
  .zhichuan-title {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-weight: 700;
  }
  .tip {
    width: 400px;
    height: 16vh;
    position: fixed;
    right: 6.66vw;
    bottom: 0;
    font-size: 24px;
    color: #353535;
    text-align: center;
    font-family: '黑体';
  }
  .tip-right {
    width: 170px;
    height: 78px;
    line-height: 78px;
    position: fixed;
    right: 131px;
    top: 32px;
    font-family: '黑体';
    color: #353535;
    font-size: 24px;
    text-align: right;
  }
  .zhichuan-tip-right {
    width: 84px;
    text-align: center;
    height: 30px;
    line-height: 30px;
    position: fixed;
    right: 24px;
    bottom: 64px;
    top: auto;
    font-size: 14px;
  }
  .qrcode {
    width: 78px;
    height: 78px;
    position: fixed;
    right: 36px;
    top: 32px;
  }
  .zhichuan-qrcode {
    width: 60px;
    height: 60px;
    position: fixed;
    right: 36px;
    bottom: 90px;
    top: auto;
  }
  footer {
    background-color: rgba(255,255,255,.7);
    width: 100%;
    height: 62px;
    line-height: 62px;
    text-align: center;
    color: #050606;
    font-size: 14px;
    position: fixed;
    left: 0;
    bottom: 0;
    span {
      margin-right: 20px;
    }
    a {
      color: #2377f7;
    }
  }
}
</style>