<template>
  <div class="login-box-f">
    <a-form style="width: 100%;" action="#" method="POST" :form="form">
      <input type="hidden" name="remember" value="true"/>
      <a-tabs
        size="large"
        activeKey="tab1"
        :tabBarStyle="{ textAlign: 'center', borderBottom: 'unset' }">
        <a-tab-pane key="tab1" tab="欢迎登录">
          <a-form-item style="margin-bottom: 0">
            <a-input
              size="large"
              v-decorator="['username', validatorRules.username, { validator: this.handleUsernameOrEmail }]"
              type="text"
              placeholder="请输入账号名/手机号">
            </a-input>
          </a-form-item>
          <a-form-item style="margin-bottom: 0">
            <a-input
              v-decorator="['password', validatorRules.password]"
              size="large"
              type="password"
              autocomplete="false"
              placeholder="请输入密码">
            </a-input>
          </a-form-item>
        </a-tab-pane>
      </a-tabs>
    </a-form>
    <a-button
      class="login-btn"
      block
      size="large"
      type="primary">
      登 录
    </a-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: this.$form.createForm(this),
      validatorRules: {
        username: {rules: [{required: true, message: '请输入账号名/手机号!'}, {validator: this.handleUsernameOrEmail}]},
        companyId: {rules: [{required: true, message: '请选择公司!'}]},
        password: {rules: [{required: true, message: '请输入密码!', validator: 'click'}]},
        mobile: {rules: [{validator: this.validateMobile}]},
        captcha: {rule: [{required: true, message: '请输入验证码!'}]},
      }
    }
  },
  methods: {
    newLogin() {
      this.form.validateFields(['username', 'password'], {force: true}, (err, values) => {
        if (!err) {
          this.$message.error('系统维护中，请稍后登录！')
        }
      })
    },
    handleUsernameOrEmail(rule, value, callback) {
      const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/;
      if (regex.test(value)) {
        this.loginType = 0
      } else {
        this.loginType = 1
      }
      callback()
    }
  }
}
</script>

<style lang="less" scoped>
.login-box-f {
  padding: 0 14px 14px 14px;
  background-color: rgba(255, 255, 255, .6);
  border-radius: 8px;
}
::v-deep .ant-tabs-nav {
  width: 100%;
  .ant-tabs-tab {
    margin: 0;
    width: 50%;
    text-align: center;
    box-sizing: border-box;
    border-bottom: 3px solid #ebebeb;
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: bold;
    color: #000;
  }
  .ant-tabs-tab-active {
    color: #1890ff;
  }
}
::v-deep .ant-input {
    width: calc(100% - 4px);
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    padding: 14px;
    border-radius: 6px;
    margin: 0 2px 20px 2px;
    border-width: 1px !important;
}
::v-deep .has-error .ant-form-explain {
  margin-top: -20px;
  margin-bottom: 10px;
  padding-left: 6px;
}
::v-deep .ant-input-affix-wrapper .ant-input-prefix {
  top: 32%;
}
::v-deep .ant-tabs-ink-bar {
  width: 100% !important;
}
.forget-pass {
  color: #666;
  font-family: '黑体';
  font-size: 16px;
  cursor: pointer;
}
.login-btn {
  margin-top: 20px;
  height: 40px;
  line-height: 40px;
  background-image: linear-gradient(to bottom, #209ffb, #0660e2)
}
.ant-input {
  border-bottom-width: 1px;
  //border: 1px solid #d9d9d9;
  border-width: 0 0 1px 0;
}
.user-layout-login {
  label {
    font-size: 14px;
  }
  .forge-password {
    font-size: 14px;
  }
  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }
  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;
    .item-icon {
      font-size: 24px;
      color: rgba(0, 0, 0, .2);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color .3s;

      &:hover {
        color: #1890ff;
      }
    }
    .register {
      float: right;
    }
  }
}

</style>
